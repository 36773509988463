import React from "react";
import { ContentSwitcher, Switch } from "@wfp/ui";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import "./_date-input.scss";

type HistoryFilterProps = {
  setFromDate: (date: moment.Moment) => void;
  setGranularity?: (granularity: "hourly" | "daily") => void;
};

const HistoryFilter: React.ElementType<HistoryFilterProps> = ({
  setFromDate,
  setGranularity,
}) => {
  const onChangeDateFilter = (e: {
    name: "last-week" | "last-month" | "last-year";
  }) => {
    let fromDate;
    switch (e.name) {
      case "last-week":
        fromDate = moment().subtract(1, "weeks").startOf("day");
        break;
      case "last-month":
        fromDate = moment().subtract(1, "months").startOf("day");
        break;
      case "last-year":
        fromDate = moment().subtract(1, "year").startOf("day");
        break;
      default:
        fromDate = moment().startOf("day");
    }
    setFromDate(fromDate);
  };

  const onChangeGranularityFilter = (e: { name: "hourly" | "daily" }) => {
    if (setGranularity) {
      setGranularity(e.name);
    }
  };

  return (
    <div className="history-filter">
      <ContentSwitcher onChange={onChangeDateFilter} small>
        <Switch
          name="last-year"
          text={
            <FormattedMessage
              id="StockHistory.filterByOneYear"
              defaultMessage="1 year"
            />
          }
          kind="anchor"
          href="#"
        />
        <Switch
          name="last-month"
          text={
            <FormattedMessage
              id="StockHistory.filterByOneMonth"
              defaultMessage="1 month"
            />
          }
        />
        <Switch
          name="last-week"
          text={
            <FormattedMessage
              id="StockHistory.filterByOneWeek"
              defaultMessage="1 week"
            />
          }
        />
      </ContentSwitcher>
      {setGranularity && (
        <ContentSwitcher onChange={onChangeGranularityFilter} small>
          <Switch
            name="hourly"
            text={
              <FormattedMessage
                id="StockHistory.filterHourly"
                defaultMessage="Hourly"
              />
            }
            kind="anchor"
            href="#"
          />
          <Switch
            name="daily"
            text={
              <FormattedMessage
                id="StockHistory.filterDaily"
                defaultMessage="Daily"
              />
            }
          />
        </ContentSwitcher>
      )}
    </div>
  );
};

export default HistoryFilter;
