import React from "react";
import moment from "moment";
import { isEmpty, difference } from "lodash";
import { FormattedMessage } from "react-intl";

import { store } from "data-handler/store";
import {
  Attendance,
  Delivery,
  EnrolmentUpdate,
  getAllDeliveriesAndPurchasesByDateRange,
  getAllSchoolYears,
  Purchase,
  SchoolYear,
  Store,
} from "data-handler/ducks/stores";
import { getEnrolmentUpdatesByDateRange } from "data-handler/ducks/stores";
import { getAttendancesByDateRange } from "data-handler/ducks/stores";
import { getWeekDay } from "helpers/dates";
// import { stockValueByDateByCommodityByCategory } from "../../helpers/stock";
// import { deliveryCategory } from "SCConstants";

const ERROR_MESSAGES = {
  weekdaysSome: (
    <FormattedMessage
      id="YearEditError.weekdaysSome"
      defaultMessage="Select at least one school day"
    />
  ),
  shiftsSome: (
    <FormattedMessage
      id="YearEditError.shiftsSome"
      defaultMessage="Select at least morning or afternoon"
    />
  ),
  duplicateLevel: (
    <FormattedMessage
      id="YearEditError.duplicateLevel"
      defaultMessage="Duplicate level entry"
    />
  ),
  missingLevel: (
    <FormattedMessage
      id="YearEditError.missingLevel"
      defaultMessage="Select a level"
    />
  ),
  dateInvalid: (
    <FormattedMessage
      id="YearEditError.dateInvalid"
      defaultMessage="Start date is invalid"
    />
  ),
  enddateInvalid: (
    <FormattedMessage
      id="YearEditError.enddateInvalid"
      defaultMessage="End date is invalid"
    />
  ),
  enddateBeforeDate: (
    <FormattedMessage
      id="YearEditError.enddateBeforeDate"
      defaultMessage="End date is before start date"
    />
  ),
  schoolYearCollision: (
    <FormattedMessage
      id="YearEditError.schoolYearCollision"
      defaultMessage="Cannot overlap other school years"
    />
  ),
  orphanedAttendanceWeekday: (
    <FormattedMessage
      id="YearEditError.orphanedAttendanceWeekday"
      defaultMessage="Cannot remove weekdays for which attendance has already been provided"
    />
  ),
  orphanedAttendanceShifts: (
    <FormattedMessage
      id="YearEditError.orphanedAttendanceShifts"
      defaultMessage="Cannot remove morning/afternoon after attendance has been provided"
    />
  ),
  orphaningStartsOn: (
    <FormattedMessage
      id="YearEditError.orphaningStartsOn"
      defaultMessage="Cannot move start date beyond the earliest date of already provided data"
    />
  ),
  orphaningEndsOn: (
    <FormattedMessage
      id="YearEditError.orphaningEndsOn"
      defaultMessage="Cannot move end date before the latest date of already provided data"
    />
  ),
  orphanedLevels: (
    <FormattedMessage
      id="YearEditError.orphanedLevels"
      defaultMessage="Cannot remove levels after attendance or enrolment updates have been provided"
    />
  ),
  missingIncidentType: (
    <FormattedMessage
      id="YearEditError.missingIncidentType"
      defaultMessage="Select an incident type"
    />
  ),
  missingIncidentReason: (
    <FormattedMessage
      id="YearEditError.missingIncidentReason"
      defaultMessage="Select an incident reason"
    />
  ),
  commodityNotInProfile: (
    <FormattedMessage
      id="YearEditError.commodityNotInProfile"
      defaultMessage="Cannot increase the initial stock of a commodity not in the school profile."
    />
  ),
};

/**
 * School Years must not overlap each other.
 */
const validateSchoolYearOverlaps = (
  values: SchoolYear,
  { currentStoreData }: { currentStoreData?: SchoolYear }
) => {
  const errors: {
    schoolYearCollision?: any;
  } = {};
  const state = store.getState();
  const allSchoolYears = getAllSchoolYears(state);

  /** A school year which overlaps the edited school year's date range */
  const collision = allSchoolYears.find((other) => {
    // Don't check collision against the edited school year itself
    if (currentStoreData && other.client_id === currentStoreData.client_id) {
      return false;
    }
    // Check for overlapping date ranges
    return (
      moment(values.starts_on).isBetween(
        other.starts_on,
        other.ends_on,
        "days",
        "[]"
      ) ||
      moment(values.ends_on).isBetween(
        other.starts_on,
        other.ends_on,
        "days",
        "[]"
      ) ||
      moment(other.starts_on).isBetween(
        values.starts_on,
        values.ends_on,
        "days",
        "[]"
      )
    );
  });

  if (collision !== undefined) {
    errors["schoolYearCollision"] = {
      message: ERROR_MESSAGES["schoolYearCollision"],
    };
  }

  return errors;
};

/**
 * Editing a School Year must not leave data orphaned
 */
const validateOrphanedData = (
  values: SchoolYear,
  { currentStoreData }: { currentStoreData?: SchoolYear }
) => {
  const errors: Record<string, any> = {};
  const state = store.getState();

  // No preexistent SchoolYear -> no related data -> no orphaned data errors
  if (!currentStoreData) return errors;

  const enrolmentUpdates = getEnrolmentUpdatesByDateRange(
    currentStoreData.starts_on,
    currentStoreData.ends_on
  )(state);

  const attendances = getAttendancesByDateRange(
    currentStoreData.starts_on,
    currentStoreData.ends_on
  )(state);

  const deliveriesAndPurchases = getAllDeliveriesAndPurchasesByDateRange(
    currentStoreData.starts_on,
    currentStoreData.ends_on
  )(state);

  // Prevent removing levels after Attendance / Enrolment Updates have been provided
  if (attendances.length > 0 || enrolmentUpdates.length > 0) {
    const prevLevels = currentStoreData.levels.map((level) => level.level);
    const nextLevels = values.levels.map((level) => level.level);
    // if a level that was in prevLevels is not in nextLevels
    if (difference(prevLevels, nextLevels).length > 0) {
      errors["levels"] = {
        message: ERROR_MESSAGES["orphanedLevels"],
      };
    }
  }

  // Prevent moving start/end dates in such a way that Attendance / Enrolment / Delivery / Purchase Updates
  // which were previously part of the SchoolYear, now fall outside of it
  [...attendances, ...enrolmentUpdates, ...deliveriesAndPurchases].forEach(
    (syncable) => {
      const syncableDate =
        (syncable as Attendance | EnrolmentUpdate).occurred_on ||
        (syncable as Delivery).delivered_at ||
        (syncable as Purchase).purchased_at;

      if (moment(syncableDate).isBefore(values.starts_on, "days")) {
        errors["starts_on"] = {
          message: ERROR_MESSAGES["orphaningStartsOn"],
        };
      }
      if (moment(syncableDate).isAfter(values.ends_on, "days")) {
        errors["ends_on"] = {
          message: ERROR_MESSAGES["orphaningEndsOn"],
        };
      }
    }
  );

  // Prevent removing Morning/Afternoon, if Attendance has already been provided
  if (attendances.length > 0) {
    if (
      (!values.has_morning_classes && currentStoreData.has_morning_classes) ||
      (!values.has_afternoon_classes && currentStoreData.has_afternoon_classes)
    ) {
      errors["has_morning_classes"] = {
        message: ERROR_MESSAGES["orphanedAttendanceShifts"],
      };
    }
  }

  // Prevent removing school days where Attendance has already been provided
  attendances.forEach((attendance) => {
    if (!values.weekdays[getWeekDay(attendance.occurred_on)]) {
      errors[`weekdays[${getWeekDay(attendance.occurred_on)}]`] = {
        message: ERROR_MESSAGES["orphanedAttendanceWeekday"],
      };
    }
  });

  return errors;
};

// This is not needed at the moment, but might be needed in the future. Commenting out for now
//
// /**
//  * The deliveries for initial stock must be validated
//  */
// const validateInitialStockData = (
//   values: Store,
//   {
//     allStores,
//     commodityListWfpIds,
//   }: {
//     allStores: Store;
//     commodityListWfpIds: any;
//   }
// ) => {
//   const errors: Record<string, any> = {};
//   if ("commodities" in values && values.commodities) {
//     values.commodities.forEach((commodity, i) => {
//       if (!commodityListWfpIds.includes(commodity.commodity)) {
//         let currentStock = stockValueByDateByCommodityByCategory(
//           allStores,
//           moment(),
//           commodity.commodity,
//           deliveryCategory
//         );
//         if (
//           parseFloat(commodity.quantity) > currentStock &&
//           commodity.commodity
//         ) {
//           errors[`commodities[${i}].quantity`] = {
//             message: ERROR_MESSAGES["commodityNotInProfile"],
//           };
//         }
//       }
//     });
//   }

//   return errors;
// };

/**
 * A react-hook-form validationResolver
 * which accesses the FE "DB" to enforce business rules.
 */
export const schoolYearValidationResolver = (
  values: SchoolYear,
  {
    currentStoreData,
    isLocked,
    allStores,
    commodityListWfpIds,
  }: {
    currentStoreData?: SchoolYear;
    isLocked: boolean;
    allStores: Store;
    commodityListWfpIds: any;
  }
) => {
  // Prevent changing levels (Especially necessary because the BE can't forbid this)
  if (isLocked && currentStoreData) {
    values.levels = currentStoreData.levels;
  }

  const errors: Record<string, any> = {
    ...validateOrphanedData(values, { currentStoreData }),
    ...validateSchoolYearOverlaps(values, { currentStoreData }),
    // This is not needed at the moment, but might be needed in the future. Commenting out for now
    //
    // ...validateInitialStockData(values, {
    //   allStores,
    //   commodityListWfpIds,
    // }),
  };

  // weekdays
  if (!values.weekdays.some((day) => day === true)) {
    errors["weekdaysSome"] = {
      message: ERROR_MESSAGES["weekdaysSome"],
    };
  }

  // Shifts (morning/afternoon)
  if (!values.has_morning_classes && !values.has_afternoon_classes) {
    errors["shiftsSome"] = {
      message: ERROR_MESSAGES["shiftsSome"],
    };
  }

  // `level` is required on each level row
  values.levels &&
    values.levels.forEach((level, i) => {
      if (!level.level) {
        errors[`levels[${i}].level`] = {
          message: ERROR_MESSAGES["missingLevel"],
        };
      }
    });

  // Duplicate level
  const levelRows = values.levels.map(function (row) {
    return row.level;
  });
  const isDuplicate = levelRows.some(function (row, idx) {
    return levelRows.indexOf(row) !== idx;
  });
  if (isDuplicate) {
    errors["levels[0].level"] = {
      message: ERROR_MESSAGES["duplicateLevel"],
    };
  }

  // startDate
  const dateValid = moment(values.starts_on, moment.ISO_8601, true).isValid();
  if (!dateValid) {
    errors["starts_on"] = {
      message: ERROR_MESSAGES["dateInvalid"],
    };
  }

  // endDate
  const enddateValid = moment(values.ends_on, moment.ISO_8601, true).isValid();
  if (!enddateValid) {
    errors["ends_on"] = {
      message: ERROR_MESSAGES["enddateInvalid"],
    };
  }

  // startDate vs endDate
  const enddateBeforeDate = moment(values.ends_on).isBefore(values.starts_on);
  if (enddateBeforeDate) {
    errors["enddateBeforeDate"] = {
      message: ERROR_MESSAGES["enddateBeforeDate"],
    };
  }

  return {
    values: !isEmpty(errors) ? {} : values,
    errors,
  };
};
