import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ModuleBody } from "@wfp/ui";

import TableExtended from "components/TableExtended";
import MeasureUnitValue from "components/MeasureUnitValue";

import styles from "./school-report.module.scss";
import { missingCommodity } from "data-handler/ducks/reports";

import { Report, ReportCommodity } from "data-handler/ducks/stores";
import commoditiesDuck from "data-handler/ducks/commodities";

type DailyAttendanceProps = {
  report: Report;
  schoolProfile: {
    enable_student_attendance: boolean;
  };
};

const DailyAttendance = ({
  report,
  schoolProfile: { enable_student_attendance },
}: DailyAttendanceProps) => {
  const commoditiesIndex = useSelector(commoditiesDuck.getIndex);

  const {
    aggregates: {
      school_day_summary_rows,
      school_day_summary_totals_row: totalsRow,
    },
  } = report;

  const getCommodity = (id: number) => {
    const defaultCommodity = {
      ...missingCommodity,
      name: `Unidentified(${id})`,
    };
    return commoditiesIndex[id] || defaultCommodity;
  };

  // Only show WFP commodities that were used
  let commoditiesUsed: any[] = [];
  school_day_summary_rows.forEach((row) => {
    const consumptionObject = row.consumption_per_commodity || {};
    Object.keys(consumptionObject).forEach((commodityId) => {
      const floatValue = parseFloat(consumptionObject[commodityId]);
      if (!(floatValue < 1 && floatValue >= 0 && floatValue % 1 === 0)) {
        commoditiesUsed.push(parseInt(commodityId));
      }
    });
  });

  const commodities: ReportCommodity[] = Object.values(commoditiesIndex);
  const filteredCommodities = (commodities || []).filter((item) =>
    commoditiesUsed.includes(item.id)
  );

  return (
    <ModuleBody className={styles.reportModule}>
      <h5 className={styles.sectionTitle}>
        <FormattedMessage
          id="dailyAttendance.title"
          defaultMessage="4. Daily attendance data (on-site meals)"
        />
      </h5>

      <div className={styles.printContainer}>
        <TableExtended>
          <thead className={styles.reportTHead}>
            <tr>
              <td className={styles.headerData} rowSpan={3}>
                <FormattedMessage
                  id="dailyAttendance.day_of_month"
                  defaultMessage="Day of the Month"
                />
              </td>
              <td className={styles.headerData}>
                <span>
                  <FormattedMessage
                    id="dailyAttendance.class_days"
                    defaultMessage="Class Days"
                  />
                </span>
              </td>
              <td className={styles.headerData}>
                <span>
                  <FormattedMessage
                    id="dailyAttendance.meal_days"
                    defaultMessage="Meal Days"
                  />
                </span>
              </td>
              {enable_student_attendance ? (
                <>
                  <td className={styles.headerData} colSpan={3}>
                    <span>
                      <FormattedMessage
                        id="dailyAttendance.studentAttendance"
                        defaultMessage="Attendance"
                      />
                    </span>
                  </td>
                  <td className={styles.headerData} colSpan={3}>
                    <span>
                      <FormattedMessage
                        id="dailyAttendance.canteenAttendance"
                        defaultMessage="Canteen Attendance"
                      />
                    </span>
                  </td>
                </>
              ) : (
                <td className={styles.headerData} colSpan={3}>
                  <span>
                    <FormattedMessage
                      id="dailyAttendance.mealAttendance"
                      defaultMessage="Attendance"
                    />
                  </span>
                </td>
              )}
              <td
                className={styles.headerData}
                colSpan={filteredCommodities.length}
              >
                <span>
                  <FormattedMessage
                    id="dailyAttendance.quantity_of_food_consumed"
                    defaultMessage="Quantity of food consumed in the Canteen (Number of students
                present) X (daily ration)"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td className={styles.headerData}>
                <FormattedMessage
                  id="dailyAttendance.1_yes"
                  defaultMessage="1 = Yes"
                />
              </td>
              <td className={styles.headerData}>
                <FormattedMessage
                  id="dailyAttendance.1_yes"
                  defaultMessage="1 = Yes"
                />
              </td>
              <td
                className={`${styles.headerData} ${styles.numCell}`}
                rowSpan={2}
              >
                <FormattedMessage
                  id="dailyAttendance.boys"
                  defaultMessage="Boys"
                />{" "}
              </td>{" "}
              <td
                className={`${styles.headerData} ${styles.numCell}`}
                rowSpan={2}
              >
                <FormattedMessage
                  id="dailyAttendance.girls"
                  defaultMessage="Girls"
                />
              </td>
              <td
                className={`${styles.headerData} ${styles.numCell}`}
                rowSpan={2}
              >
                <FormattedMessage
                  id="dailyAttendance.total"
                  defaultMessage="Total"
                />
              </td>
              {enable_student_attendance && (
                <>
                  <td
                    className={`${styles.headerData} ${styles.numCell}`}
                    rowSpan={2}
                  >
                    <FormattedMessage
                      id="dailyAttendance.boys"
                      defaultMessage="Boys"
                    />
                  </td>
                  <td
                    className={`${styles.headerData} ${styles.numCell}`}
                    rowSpan={2}
                  >
                    <FormattedMessage
                      id="dailyAttendance.girls"
                      defaultMessage="Girls"
                    />
                  </td>
                  <td
                    className={`${styles.headerData} ${styles.numCell}`}
                    rowSpan={2}
                  >
                    <FormattedMessage
                      id="dailyAttendance.total"
                      defaultMessage="Total"
                    />
                  </td>
                </>
              )}
              {filteredCommodities.map((commodity) => (
                <td
                  key={commodity.name}
                  className={`${styles.headerData} ${styles.numCell}`}
                  rowSpan={2}
                >
                  {commodity.name}
                </td>
              ))}
            </tr>
            <tr>
              <td className={styles.headerData}>
                <FormattedMessage
                  id="dailyAttendance.0_no"
                  defaultMessage="0 = No"
                />
              </td>
              <td className={styles.headerData}>
                <FormattedMessage
                  id="dailyAttendance.0_no"
                  defaultMessage="0 = No"
                />
              </td>
            </tr>
          </thead>
          <tbody>
            {/* School day summary rows */}

            {school_day_summary_rows.map((row, i) => (
              <tr key={i}>
                <td className={styles.headerData}>
                  {moment(row.date).format("D")}
                </td>
                <td className={styles.headerData}>
                  {Number(row.is_school_day)}
                </td>
                <td className={styles.headerData}>
                  {Number(row.is_consumption_day)}
                </td>
                {enable_student_attendance ? (
                  <>
                    {/* 2 buttons-student attendance flow */}
                    <td className={`${styles.headerData} ${styles.numCell}`}>
                      {row.student_attendance_per_kind.male}
                    </td>
                    <td className={`${styles.headerData} ${styles.numCell}`}>
                      {row.student_attendance_per_kind.female}
                    </td>
                    <td className={`${styles.headerData} ${styles.numCell}`}>
                      {row.student_attendance_per_kind.male +
                        row.student_attendance_per_kind.female}
                    </td>
                    {/* Canteen attendance */}
                    <td className={`${styles.headerData} ${styles.numCell}`}>
                      {row.attendance_per_kind.male}
                    </td>
                    <td className={`${styles.headerData} ${styles.numCell}`}>
                      {row.attendance_per_kind.female}
                    </td>
                    <td className={`${styles.headerData} ${styles.numCell}`}>
                      {row.attendance_per_kind.male +
                        row.attendance_per_kind.female}
                    </td>
                  </>
                ) : (
                  <>
                    {/* Normal flow */}
                    <td className={`${styles.headerData} ${styles.numCell}`}>
                      {row.attendance_per_kind.male}
                    </td>
                    <td className={`${styles.headerData} ${styles.numCell}`}>
                      {row.attendance_per_kind.female}
                    </td>
                    <td className={`${styles.headerData} ${styles.numCell}`}>
                      {row.attendance_per_kind.male +
                        row.attendance_per_kind.female}
                    </td>
                  </>
                )}

                {filteredCommodities.map((commodity) => (
                  <td
                    key={commodity.id}
                    className={`${styles.headerData} ${styles.numCell}`}
                  >
                    <MeasureUnitValue
                      measureUnit={getCommodity(commodity.id).measure_unit}
                    >
                      {row.consumption_per_commodity[commodity.id]}
                    </MeasureUnitValue>
                    <br />
                  </td>
                ))}
              </tr>
            ))}

            {/* School day summary totals row */}

            <tr className={styles.boldText}>
              <td className={styles.headerData}>
                <FormattedMessage
                  id="dailyAttendance.total"
                  defaultMessage="Total"
                />
              </td>
              <td className={styles.headerData}>{totalsRow.school_days}</td>
              <td className={styles.headerData}>
                {totalsRow.consumption_days}
              </td>

              {enable_student_attendance ? (
                <>
                  <td className={`${styles.headerData} ${styles.numCell}`}>
                    {totalsRow.student_attendance_per_kind.male}
                  </td>
                  <td className={`${styles.headerData} ${styles.numCell}`}>
                    {totalsRow.student_attendance_per_kind.female}
                  </td>
                  <td className={`${styles.headerData} ${styles.numCell}`}>
                    {totalsRow.student_attendance_per_kind.male +
                      totalsRow.student_attendance_per_kind.female}
                  </td>

                  <td className={`${styles.headerData} ${styles.numCell}`}>
                    {totalsRow.attendance_per_kind.male}
                  </td>
                  <td className={`${styles.headerData} ${styles.numCell}`}>
                    {totalsRow.attendance_per_kind.female}
                  </td>
                  <td className={`${styles.headerData} ${styles.numCell}`}>
                    {totalsRow.attendance_per_kind.male +
                      totalsRow.attendance_per_kind.female}
                  </td>
                </>
              ) : (
                <>
                  <td className={`${styles.headerData} ${styles.numCell}`}>
                    {totalsRow.attendance_per_kind.male}
                  </td>
                  <td className={`${styles.headerData} ${styles.numCell}`}>
                    {totalsRow.attendance_per_kind.female}
                  </td>
                  <td className={`${styles.headerData} ${styles.numCell}`}>
                    {totalsRow.attendance_per_kind.male +
                      totalsRow.attendance_per_kind.female}
                  </td>
                </>
              )}

              {filteredCommodities.map((commodity) => (
                <td
                  key={commodity.id}
                  className={`${styles.headerData} ${styles.numCell}`}
                >
                  <MeasureUnitValue
                    measureUnit={getCommodity(commodity.id).measure_unit}
                  >
                    {totalsRow.consumption_per_commodity &&
                      totalsRow.consumption_per_commodity[commodity.id]}
                  </MeasureUnitValue>
                  <br />
                </td>
              ))}
            </tr>
          </tbody>
        </TableExtended>
      </div>
    </ModuleBody>
  );
};

export default DailyAttendance;
