import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import { Module, ModuleBody, ModuleHeader, Value } from "@wfp/ui";

import { getStockStatus } from "helpers/stock";
import {
  getSyncableMoment,
  getAllStores,
  getStoreDate,
  StockMovementStore,
} from "data-handler/ducks/stores";
import commoditiesDuck from "data-handler/ducks/commodities";

import StockHistoryChart from "components/StockHistoryChart";
import MeasureUnitValue from "components/MeasureUnitValue";
import ItemSwitcher from "components/ItemSwitcher";
import HistoryFilter from "components/HistoryFilter";
import MainContent from "components/MainContent";

import "./_stock-history.scss";
import {
  deliveryCategory,
  purchaseDetailCategory,
  takeHomeRationCategory,
} from "SCConstants";
import { RootState } from "data-handler/rootReducer";

type Category =
  | typeof deliveryCategory
  | typeof purchaseDetailCategory
  | typeof takeHomeRationCategory;

const stockChartPointByDateByCommodity = (
  state: RootState,
  date: string,
  commodityId: number,
  category: Category,
  granularity: "hourly" | "daily"
) => {
  const isHourly = granularity === "hourly";
  return {
    date: moment(date).format("YYYY-MM-DDTHH:mm:ss"),
    formatDate: moment(date).format("X"),
    value: getStockStatus(date, isHourly)(state)[category][
      commodityId.toString()
    ],
  };
};

function StockHistory() {
  const params: any = useParams();
  const state = useSelector((state: RootState) => state);
  const commoditiesIndex = useSelector(commoditiesDuck.getIndex);
  const commodityId = parseInt(params.item);
  const commodityName =
    commoditiesIndex[commodityId] && commoditiesIndex[commodityId].name;
  const commodityUnit =
    commoditiesIndex[commodityId] && commoditiesIndex[commodityId].measure_unit;
  const categoryParam = params.details;

  const [fromDate, setFromDate] = useState(moment().subtract(1, "year"));
  const [granularity, setGranularity] = useState<"hourly" | "daily">("hourly");
  const allStores = useSelector(getAllStores);

  let category: Category;
  switch (categoryParam) {
    case "purchase":
      category = purchaseDetailCategory;
      break;
    case "takeHomeRations":
      category = takeHomeRationCategory;
      break;
    default:
      category = deliveryCategory;
      break;
  }

  const filteredStores = (allStores as StockMovementStore[])
    // Narrow stores down to only those which affect this page's commodity
    .filter((store: any) => {
      switch (store.model) {
        case "attendance":
          return store.consumption?.commodities?.find(
            (commodity: { commodity: number; category: string }) =>
              commodity.commodity === commodityId &&
              commodity.category === category
          );
        case "purchasedetail":
        case "takehomeration":
        case "delivery":
        case "incident":
          return store.commodities?.find(
            (commodity: { commodity: number; category: string }) =>
              commodity.commodity === commodityId &&
              commodity.category === category
          );
        default:
          return false;
      }
    })
    // Narrow down to stores within the selected date range
    .filter((store) => fromDate.isSameOrBefore(getSyncableMoment(store)));

  const chartPointOnDateRangeStart = stockChartPointByDateByCommodity(
    state,
    fromDate.format("YYYY-MM-DDTHH:mm:ss"),
    commodityId,
    category,
    granularity
  );
  const chartPointOnDateRangeEnd = stockChartPointByDateByCommodity(
    state,
    moment().format("YYYY-MM-DDTHH:mm:ss"),
    commodityId,
    category,
    granularity
  );

  const stockHistoryList = [
    chartPointOnDateRangeStart,
    ...filteredStores
      .map((store) =>
        stockChartPointByDateByCommodity(
          state,
          getStoreDate(store, granularity === "hourly") ||
            moment().format("YYYY-MM-DDTHH:mm:ss"),
          commodityId,
          category,
          granularity
        )
      )
      .sort((a, b) => (a.formatDate > b.formatDate ? 1 : -1)),
    chartPointOnDateRangeEnd,
  ];

  return (
    <MainContent
      title={
        <>
          <FormattedMessage
            id="StockHistory.stockHistoryFor"
            defaultMessage="Stock history for {commodityName}"
            values={{ commodityName }}
          />
        </>
      }
    >
      <div className="stock-history__overview">
        <Module noMargin dark className="stock-history__current-stock">
          <ModuleBody>
            <Value
              title={
                <FormattedMessage
                  id="StockHistory.currentlyInStock"
                  defaultMessage="Currently in stock"
                />
              }
              value={
                <MeasureUnitValue measureUnit={commodityUnit}>
                  {chartPointOnDateRangeEnd.value}
                </MeasureUnitValue>
              }
              secondaryValue={commodityName}
            />
          </ModuleBody>
        </Module>
        <Module noMargin className="stock-history__chart">
          <ModuleHeader
            filter={
              <HistoryFilter
                setFromDate={setFromDate}
                setGranularity={setGranularity}
              />
            }
          >
            <FormattedMessage
              id="StockHistory.historyChartFor"
              defaultMessage="History chart for {commodityName}"
              values={{ commodityName }}
            />
          </ModuleHeader>
          <ModuleBody>
            <StockHistoryChart
              data={stockHistoryList}
              measureUnit={commodityUnit}
            />
          </ModuleBody>
        </Module>
      </div>
      <Module noMargin className="stock-history__list">
        <ModuleBody noPadding>
          {filteredStores.map((store) => (
            <ItemSwitcher store={store} key={store.client_id} />
          ))}
        </ModuleBody>
      </Module>
    </MainContent>
  );
}
export default StockHistory;
