import React from "react";
import { useSelector } from "react-redux";

import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import queryString from "query-string";
import withSizes from "react-sizes";
import classNames from "classnames";
import { InlineLoading } from "@wfp/ui";
import {
  getCurrentSchool,
  getIsSchoolsFetching,
} from "data-handler/ducks/schools";
import {
  getCurrentUser,
  getIsViewer,
  getOnlineRequiredError,
} from "data-handler/ducks/auth";
import { mapSizesToProps } from "helpers/mapSizesToProps";
import Attendance from "components/Attendance";
import SupportModal from "components/SupportModal";
import Deliveries from "components/Deliveries";
import Info from "components/Info";
import MainNav from "components/MainNav";
import MobileNav from "components/MobileNav";
import Report from "components/Report";
import SchoolSelect from "components/SchoolSelect";
import Stock from "components/Stock";
import SyncSelect from "components/SyncSelect";
import UserModal from "components/UserModal";
import UpdateLocalPassword from "components/Login/UpdateLocalPassword";
import UserHasNoSchool from "./UserHasNoSchool";
import SchoolHasNoProfile from "./SchoolHasNoProfile";
import "./_content.scss";
import OnlineRequiredModal from "../OnlineRequiredModal/OnlineRequiredModal";
import Initializer from "components/Initializer";
import SchoolInitializer from "components/SchoolInitializer";
import { useSchoolIdFromURL } from "helpers/schools";

import {
  getInitializerIsFetching,
  getSchoolInitializerIsFetching,
} from "data-handler/ducks/initialization";

type ContentProps = {
  isMobile: boolean;
};

/**
 * - Renders Nav, according to isMobile
 * - Renders Pages, according to routing
 * - Loads SC primitives, on first access
 *
 * This component must be accessible _only_ to authenticated users.
 */
const Content: React.ComponentType<ContentProps> = ({ isMobile }) => {
  const location = useLocation();
  const urlParams = queryString.parse(location.search);
  const currentUser = useSelector(getCurrentUser);
  const currentSchool = useSelector(getCurrentSchool);
  const isSchoolsFetching = useSelector(getIsSchoolsFetching);
  const onlineRequiredError = useSelector(getOnlineRequiredError);
  const isViewer = useSelector(getIsViewer);

  const isInitializerFetching = useSelector(getInitializerIsFetching);
  const isSchoolInitializerFetching = useSelector(
    getSchoolInitializerIsFetching
  );

  const schoolIdFromURL = useSchoolIdFromURL();

  const isFetching =
    isSchoolsFetching || isInitializerFetching || isSchoolInitializerFetching;

  if (!currentSchool && isSchoolsFetching) {
    return <InlineLoading />;
  }

  // Show an actionable explanation to users with broken user data
  if (
    !currentUser.school &&
    !currentUser.is_admin &&
    !currentUser.is_approver &&
    !currentUser.is_country_admin &&
    !currentUser.is_school_group_admin &&
    !isViewer
  ) {
    return <UserHasNoSchool />;
  }

  // Normally when we load the application we do not have current school and school profile.
  // As a result we show a loading animation for a second to user and when those data shows up
  // we show the actual page. The loading animation is here so we would not show the page
  // until at least the current school is assigned.
  if (currentUser && !currentSchool) {
    return <InlineLoading />;
  }

  if (currentUser && !currentSchool?.profile) {
    return <SchoolHasNoProfile />;
  }
  if (currentUser && onlineRequiredError) {
    return <OnlineRequiredModal />;
  }

  return (
    <div
      className={classNames("content", {
        content__active: urlParams.nav,
      })}
    >
      {/* Modals */}
      {urlParams.select === "school" && <SchoolSelect />}
      <Route path="/school/:schoolId/" component={UpdateLocalPassword} />
      <Route path="/school/:schoolId/" component={SyncSelect} />
      <Route path="/school/:schoolId/" component={SupportModal} />
      <Route path="/school/:schoolId/" component={UserModal} />
      {/* Nav */}
      <Switch>
        <Route
          path="/school/:schoolId/:page?/:item?/:details?"
          render={(props) =>
            isMobile ? (
              <MobileNav {...props} />
            ) : (
              //<MainNav match={schoolMatch} {...props} />
              // TODO : check if this is working
              <MainNav {...props} />
            )
          }
        />
      </Switch>
      {/* Pages */}
      <div className="inner-content">
        {isFetching ? (
          <InlineLoading />
        ) : (
          <Switch>
            <Route
              path="/school/:schoolId/attendance/:item?/:thrItem?/:studentItem?/:details?"
              component={Attendance}
            />
            <Route
              path="/school/:schoolId/deliveries/:item?/:details?"
              component={Deliveries}
            />
            <Route
              path="/school/:schoolId/stock/:item?/:details?"
              component={Stock}
            />
            <Route
              path="/school/:schoolId/info/:item?/:details?"
              component={Info}
            />
            <Route
              path="/school/:schoolId/report/:item?/:details?"
              component={Report}
            />
            {/* Redirect to stock overview page */}
            <Route
              exact
              path="/:school/:schoolId/"
              component={() => (
                <Redirect
                  to={`/school/${schoolIdFromURL}/stock/overview?nav=true`}
                />
              )}
            />
            {currentUser &&
              (currentUser.is_admin ||
                isViewer ||
                currentUser.is_approver ||
                currentUser.is_country_admin ||
                currentUser.is_school_group_admin) && (
                <Route component={SchoolSelect} />
              )}
          </Switch>
        )}
      </div>
    </div>
  );
};

const InitializedContent: React.ComponentType<ContentProps> = (props) => {
  return (
    <Initializer>
      <SchoolInitializer>
        <Content {...props} />
      </SchoolInitializer>
    </Initializer>
  );
};

export default withSizes(mapSizesToProps)(InitializedContent);
