import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";

import { Blockquote, Button, Module, ModuleBody } from "@wfp/ui";

import SubTitle from "components/SubTitle";
import ItemSwitcher from "components/ItemSwitcher";
import SchoolReport from "components/SchoolReport";
import MainContent from "components/MainContent";
import ConfirmationModal from "components/CountryAdmin/CountryAdminContent/ConfirmationModal";

import {
  getAllStores,
  getSyncableMoment,
  syncableSortFnDecDateTime,
  getAllAttendanceStoresByDay,
  Store,
} from "data-handler/ducks/stores";
import { Report } from "data-handler/ducks/stores";
import { withSCSizes } from "helpers/mapSizesToProps";

import ReportActionButtons from "./ReportActionButtons";

import styles from "./styles.module.scss";
import { getCurrentUser } from "data-handler/ducks/auth";
import {
  isMealAttendance,
  isStudentAttendance,
  isTakeHomeRationAttendance,
} from "helpers/stores";
import { getCurrentSchoolProfile } from "data-handler/ducks/schools";
import { useReactToPrint } from "react-to-print";

const ReportContent = ({
  report,
  isMobile,
}: {
  report: Report;
  isMobile: boolean;
}) => {
  const intl = useIntl();

  const allStores = useSelector(getAllStores);
  const currentUser = useSelector(getCurrentUser);
  const attendanceStoresByDay = useSelector(getAllAttendanceStoresByDay);

  const currentSchoolProfile = useSelector(getCurrentSchoolProfile);

  const contentToPrint = useRef<HTMLDivElement>(null);

  const currentSchoolEnabledStudentAttendance =
    currentSchoolProfile.enable_student_attendance;

  const currentSchoolEnabledTHR = currentSchoolProfile.enable_take_home_rations;

  const [confirmationModalState, setConfirmationModalState] = useState(false);

  const startDate = moment(
    `${report.year}/${report.month}/${report.start_day}`
  );
  const endDate = moment(`${report.year}/${report.month}/${report.end_day}`);

  const relatedStores = allStores
    .filter((store) => {
      const isInRange = getSyncableMoment(store)?.isBetween(
        startDate,
        endDate,
        "days",
        "[]"
      );
      // Do not show studentAttendance and thrAttendance as separate attendances since they do not have urls.
      return isInRange;
    })
    .sort(syncableSortFnDecDateTime);

  const title = report.isPreview
    ? intl.formatMessage(
        {
          id: "Report.titleWhenPreview",
          defaultMessage: "Report preview {period}",
        },
        { period: startDate.format("MMMM YYYY") }
      )
    : intl.formatMessage(
        {
          id: "Report.title",
          defaultMessage: "Report {period}",
        },
        { period: startDate.format("MMMM YYYY") }
      );

  const handlePrint = useReactToPrint({
    contentRef: contentToPrint,
    documentTitle: title.toLowerCase().replace(/\s+/g, "_"),
    preserveAfterPrint: false,
    onAfterPrint: () => {
      setConfirmationModalState(false);
    },
    onPrintError: (errorLocation, error) => {
      console.log("PDF generation error:", errorLocation, error);
    },
  });

  return (
    <>
      <ConfirmationModal
        confirmationModalState={confirmationModalState}
        modalType={"submit"}
        setConfirmationModalState={setConfirmationModalState}
        onConfirmationModalSubmit={() => handlePrint()}
        submitMessage={
          <FormattedMessage
            id="Report.generatePdfConfirmation"
            defaultMessage="Would you like to download this monthly report as pdf?"
          />
        }
      />
      <MainContent
        title={title}
        subTitle={
          !isMobile &&
          `${startDate.format("DD.MM.YYYY")} – ${endDate.format("DD.MM.YYYY")}`
        }
        right={
          <div className={styles.reportButtons}>
            <Button
              onClick={() => {
                setConfirmationModalState(true);
              }}
            >
              <FormattedMessage
                id="Report.generatePdf"
                defaultMessage="Generate PDF"
              />
            </Button>
            <ReportActionButtons report={report} />
          </div>
        }
        className="report-content"
      >
        {report.isPreview && (
          <Blockquote kind="warning">
            <FormattedMessage
              id="Report.pleaseSync"
              defaultMessage="This report is a preview. If you want to close, sign, or submit the report, you must first synchronise."
            />
          </Blockquote>
        )}

        {report.negative_stock && (
          <Blockquote kind="error">
            <FormattedMessage
              id="Report.negative_stock"
              defaultMessage="Dear {user}, the report cannot be closed because it contains negative total stock values. Please correct the data to be able to close the report"
              values={{
                user: `${currentUser.other_names} ${currentUser.last_name}`,
              }}
            />
          </Blockquote>
        )}

        <Module noMargin>
          <ModuleBody noPadding>
            <div className="only-print" ref={contentToPrint}>
              <SchoolReport report={report} />
            </div>
          </ModuleBody>
        </Module>
        <SubTitle>
          <FormattedMessage
            id="Report.listOfAllRelatedEntries"
            defaultMessage="List of all related entries"
          />
        </SubTitle>
        <Module noMargin className={styles.list}>
          <ModuleBody noPadding>
            {relatedStores.map((store: Store) => {
              if (
                isMealAttendance(store) ||
                isStudentAttendance(store) ||
                isTakeHomeRationAttendance(store)
              ) {
                const data = attendanceStoresByDay[store.occurred_on as string];

                return (
                  <ItemSwitcher
                    store={data.mealAttendance}
                    studentStore={data.studentAttendance}
                    thrStore={data.thrAttendance}
                    storeToChooseType={store as any}
                    currentSchoolEnabledStudentAttendance={
                      currentSchoolEnabledStudentAttendance
                    }
                    currentSchoolEnabledTHR={currentSchoolEnabledTHR}
                    key={store.client_id}
                  />
                );
              } else {
                return <ItemSwitcher store={store} key={store.client_id} />;
              }
            })}
          </ModuleBody>
        </Module>
      </MainContent>
    </>
  );
};

export default withSCSizes(ReportContent);
