import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import withSizes from "react-sizes";
import moment from "moment";

import {
  getAttendanceStores,
  getThrAttendanceStores,
  getStudentAttendanceStores,
  getAllAttendanceStoresByDay,
} from "data-handler/ducks/stores";

import { buildAttendanceUrl } from "helpers/attendance";

import { mapSizesToProps } from "helpers/mapSizesToProps";
import { getWeekDay } from "helpers/dates";

import SchoolYearGroupDropdown from "components/SchoolYearGroupDropdown";

import { getMonthsInSchoolYear, getSortedMonths } from "helpers/dates";

/**
 * Creates a placeholder for an attendance report in the attendance sidebar
 *
 * TODO: looks like none of this is being used except for client_id,
 * which AttendanceContent translates to `occurred_on` on new attendances
 */
const attendancePlaceholderFactory = (occurred_on) => ({
  client_id: "newDate+" + moment(occurred_on).format("YYYY-MM-DD"),
  occurred_on: moment(occurred_on).format("YYYY-MM-DD"),
  model: "attendance",
  sync: false,
});

/**
 * Generates `moment` objects for each school day in the given date range
 */
function* getSchoolDaysByDateRange(date0, date1, schoolWeekdays) {
  let occurred_on = moment(date0);
  while (occurred_on.isSameOrBefore(date1)) {
    if (schoolWeekdays[getWeekDay(occurred_on)]) {
      yield moment(occurred_on);
    }
    occurred_on = occurred_on.add(1, "day");
  }
}

const AttendanceSidebar = ({
  isMobile,
  currentSchoolYear,
  previousSchoolYears,
  currentSchoolProfile,
}) => {
  const history = useHistory();
  const params = useParams();
  const attendanceStores = useSelector(getAttendanceStores);
  const studentAttendanceStores = useSelector(getStudentAttendanceStores);
  const thrAttendanceStores = useSelector(getThrAttendanceStores);
  const attendanceStoresByDay = useSelector(getAllAttendanceStoresByDay);

  const schoolYearsArray = currentSchoolYear
    ? [currentSchoolYear, ...previousSchoolYears]
    : [...previousSchoolYears];
  const currentSchoolEnabledTHR = currentSchoolProfile.enable_take_home_rations;
  const currentSchoolEnabledStudentAttendance =
    currentSchoolProfile.enable_student_attendance;
  /**
   * Create the schoolYears array from by getting all valid years from
   * the currentSchoolYear and all the previousSchoolYears.
   * Append months and collapsed fields to every school year item
   */
  const [schoolYears, setSchoolYears] = useState(
    schoolYearsArray
      .filter((item) => (item ? true : false))
      .map((item) => ({
        ...item,
        months: getMonthsInSchoolYear(item, schoolYearsArray),
        collapsed: schoolYearsArray.indexOf(item) === 0 ? false : true,
      }))
  );

  /**
   * Get the attendances and meals for a given month in a school year
   */
  const attendancesAndPlaceholders = (schoolYear, month) => {
    const attendanceDays =
      schoolYear && month
        ? [
            ...getSchoolDaysByDateRange(
              schoolYear.months[month].starts_on,
              schoolYear.months[month].ends_on,
              schoolYear.weekdays
            ),
          ]
        : [];
    return attendanceDays
      .map((occurred_on) => {
        const attendance = attendanceStores.find((attendance) =>
          occurred_on.isSame(attendance.occurred_on, "days")
        );
        const matchingThrAttendance = thrAttendanceStores.find(
          (thrAttendance) =>
            occurred_on.isSame(thrAttendance.occurred_on, "days")
        );
        const matchingStudentAttendance = studentAttendanceStores.find(
          (studentAttendance) =>
            occurred_on.isSame(studentAttendance.occurred_on, "days")
        );

        return {
          attendance: attendance
            ? {
                ...attendance,
              }
            : {
                ...attendancePlaceholderFactory(occurred_on),
              },
          studentAttendance: matchingStudentAttendance,
          thrAttendance: matchingThrAttendance,
          occurredOn: occurred_on,
          currentSchoolEnabledStudentAttendance,
          currentSchoolEnabledTHR,
        };
      })
      .sort((a, b) => {
        return b.occurredOn.isAfter(a.occurredOn) ? 1 : -1;
      });
  };

  useEffect(() => {
    if (params.item) {
      return;
    }
    const placeholders = attendancesAndPlaceholders(
      schoolYears[0],
      getSortedMonths(schoolYears[0].months)[0]
    );
    // Open the first attendance entry within the first month of the first school year
    if (schoolYears[0] && placeholders.length > 0 && !isMobile) {
      const occurredOn = placeholders[0].occurredOn.format("YYYY-MM-DD");
      const data = attendanceStoresByDay[occurredOn];

      history.push(
        buildAttendanceUrl(
          occurredOn,
          params.schoolId,
          data?.mealAttendance?.client_id,
          data?.thrAttendance?.client_id,
          data?.studentAttendance?.client_id,
          params.item,
          params.thrItem
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolYears, history, isMobile, params.item]);

  // Update the collapsed field for the according item (school year or month)
  function setCollapsedYearOrMonth(schoolYear, month) {
    let updatedSchoolYears = [...schoolYears];
    const index = updatedSchoolYears.indexOf(schoolYear);
    if (!month) {
      const collapsed = updatedSchoolYears[index].collapsed;
      updatedSchoolYears[index].collapsed = !collapsed;
    } else {
      const collapsed = updatedSchoolYears[index].months[month].collapsed;
      updatedSchoolYears[index].months[month].collapsed = !collapsed;
    }
    setSchoolYears(updatedSchoolYears);
  }

  return (
    <SchoolYearGroupDropdown
      schoolYears={schoolYears}
      setCollapsedYearOrMonth={setCollapsedYearOrMonth}
      currentSchoolYear={currentSchoolYear}
      attendancesAndPlaceholders={attendancesAndPlaceholders}
      type={"attendanceAndMeals"}
      byMonths={true}
      currentSchoolEnabledStudentAttendance={
        currentSchoolEnabledStudentAttendance
      }
      currentSchoolEnabledTHR={currentSchoolEnabledTHR}
    />
  );
};

export default withSizes(mapSizesToProps)(AttendanceSidebar);
