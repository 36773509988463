import React, { PureComponent } from "react";
import { connect } from "react-redux";
import withSizes from "react-sizes";
import { compose } from "redux";
import moment from "moment";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Scatter,
} from "recharts";
import Area from "recharts/lib/cartesian/Area";
import AreaChart from "recharts/lib/chart/AreaChart";
import Tooltip from "recharts/lib/component/Tooltip";

import { colors } from "@wfp/ui";

import { mapSizesToProps } from "helpers/mapSizesToProps";
import { getAllStores } from "data-handler/ducks/stores";
import { ChartTooltipContent } from "components/ChartTooltip";

import "./_historyChart.scss";

const CustomizedDot = ({ cx, cy }) => (
  <circle
    cx={cx}
    cy={cy}
    r={3}
    stroke="white"
    strokeWidth={0.8}
    fill={colors["interactive-01"].hex}
  />
);

class StockHistoryChart extends PureComponent {
  monthTickFormatter = (tick) => {
    const date = new Date(tick);

    return date.getMonth() + 1;
  };

  renderQuarterTick = (tickProps) => {
    const { x, y, payload, index } = tickProps;
    const { date, offset } = payload;
    // TODO: this code doesn't really calculate "previous day".
    const previousDay = this.props.stores[index - 1]
      ? moment(this.props.stores[index - 1].time).format("DD.MM.")
      : undefined;
    const currentDay = moment(date).format("DD.MM.");
    //tickFormatter={timeStr => moment(value).format('DD.MM. HH:mm')}

    if (currentDay !== previousDay) {
      // eslint-disable-next-line no-use-before-define
      //const pathX = Math.floor(isLast ? x + offset * 2 : x) + 0.5;
      return (
        <g>
          <text
            x={x + offset + 60}
            y={y + 10}
            textAnchor="middle"
          >{`${currentDay}`}</text>
          <path d={`M${0},${y - 3}v${-35}`} className="chart-tick--large" />
        </g>
      );
    }

    return null;
  };

  render() {
    const { data, measureUnit } = this.props;

    if (!data) return null;

    data.forEach((item) => {
      item.value = parseFloat(item.value);
    });

    const gradientOffset = () => {
      const dataMax = Math.max(...data.map((i) => i.value));
      const dataMin = Math.min(...data.map((i) => i.value));

      if (dataMax <= 0) {
        return 0;
      } else if (dataMin >= 0) {
        return 1;
      } else {
        return dataMax / (dataMax - dataMin);
      }
    };

    const off = gradientOffset();

    const { isMobile } = this.props;

    return (
      <ResponsiveContainer width="100%" aspect={isMobile ? 4.0 / 2 : 4.0 / 1.2}>
        <AreaChart
          data={data}
          padding={0}
          margin={{
            top: isMobile ? 20 : 30,
            right: isMobile ? 10 : 30,
            bottom: isMobile ? 0 : 10,
            left: isMobile ? 10 : 0,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset={off}
                stopColor={colors["interactive-01"].hex}
                stopOpacity={0.1}
              />
              <stop
                offset={off}
                stopColor={colors["support-01"].hex}
                stopOpacity={0.2}
              />
            </linearGradient>

            <linearGradient id="gradientUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="#f5f5f5" />

          <XAxis
            dataKey="formatDate"
            domain={["dataMin", "dataMax"]}
            type="number"
            tick={{ fontSize: 12 }}
            tickCount={8}
            tickFormatter={(timeStr) => moment.unix(timeStr).format("DD.MM.")}
          />
          <YAxis
            dataKey="value"
            tick={{ fontSize: 12 }}
            tickFormatter={(value) => value + measureUnit.symbol}
          />

          <Tooltip
            animationDuration={20}
            position={() => {
              return { x: 400, y: 0 };
            }}
            content={
              <ChartTooltipContent
                labels={{
                  value: `Stock quantity (${measureUnit.name})`,
                }}
              />
            }
          />
          {/* <Legend /> */}
          <Area
            animationDuration={150}
            dataKey="value"
            fill="url(#colorUv)"
            fillOpacity={1}
            stroke={colors["interactive-01"].hex}
            strokeWidth={2}
            type="stepAfter"
          />
          <ReferenceLine y={0} stroke="#000" />
          <Scatter
            animationDuration={150}
            dataKey="value"
            shape={<CustomizedDot />}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stores: getAllStores(state),
  };
};

export default compose(
  withSizes(mapSizesToProps),
  connect(mapStateToProps)
)(StockHistoryChart);
