import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ModuleBody } from "@wfp/ui";

import TableExtended from "components/TableExtended";

import commoditiesDuck from "data-handler/ducks/commodities";
import { missingCommodity } from "data-handler/ducks/reports";
import { Report } from "data-handler/ducks/stores";

import styles from "./school-report.module.scss";
import StockMovementsCategorySection from "./StockMovementsCategorySection";

type StockMovementsProps = {
  report: Report;
};

const StockMovements: React.ComponentType<StockMovementsProps> = ({
  report,
}) => {
  const commoditiesIndex = useSelector(commoditiesDuck.getIndex);
  const {
    aggregates: {
      stock_movement_rows_delivery,
      stock_movement_totals_row_delivery,
      stock_movement_rows_takehomeration,
      stock_movement_totals_row_takehomeration,
      stock_movement_rows_purchasedetail,
      stock_movement_totals_row_purchasedetail,
    },
  } = report;

  const getCommodity = (id: number) => {
    const defaultCommodity = {
      ...missingCommodity,
      name: `Unidentified(${id})`,
    };
    return commoditiesIndex[id] || defaultCommodity;
  };

  return (
    <ModuleBody className={styles.reportModule}>
      <h5 className={styles.sectionTitle}>
        <FormattedMessage
          id="stockMovement.movement_of_food_stock_title"
          defaultMessage="3. Movements of food stocks for hot meals (source: figures on stock
                cards)"
        />
      </h5>

      <div className={styles.printContainer}>
        <TableExtended>
          <thead className={styles.reportTHead}>
            <tr>
              <td className={styles.headerData} rowSpan={2}>
                <FormattedMessage
                  id="stockMovement.schoolFeedingModality"
                  defaultMessage="School feeding modality"
                />
              </td>
              <td className={styles.headerData} rowSpan={2}>
                <FormattedMessage
                  id="stockMovement.number"
                  defaultMessage="No"
                />
              </td>
              <td className={styles.headerData} rowSpan={2}>
                <FormattedMessage
                  id="CommodityShow.commodityTitle"
                  defaultMessage="Commodity"
                />
              </td>
              <td className={styles.headerData} rowSpan={2}>
                <FormattedMessage
                  id="stockMovement.batch_number"
                  defaultMessage="Batch Number"
                />
              </td>
              <td className={styles.headerData}>
                <FormattedMessage
                  id="stockMovement.openingStock"
                  defaultMessage="Opening stock at start of month"
                />
              </td>
              <td className={styles.headerData}>
                <FormattedMessage
                  id="stockMovement.quantity_received"
                  defaultMessage="Quantity received during the month"
                />
              </td>
              <td className={styles.headerData}>
                <FormattedMessage
                  id="stockMovement.quantity_distributed"
                  defaultMessage="Quantity distributed"
                />
              </td>
              <td className={styles.headerData}>
                <FormattedMessage
                  id="stockMovement.losses"
                  defaultMessage="Losses"
                />
              </td>
              <td className={styles.headerData}>
                <FormattedMessage
                  id="stockMovement.returned_to_wfp"
                  defaultMessage="Returned to W.F.P."
                />
              </td>
              <td className={styles.headerData}>
                <FormattedMessage
                  id="stockMovement.balance_at_end_of_month"
                  defaultMessage="Balance at end of month (a+b)-(c+d+e)"
                />
              </td>
            </tr>

            <tr>
              <td className={`${styles.headerData} ${styles.numCell}`}>
                <FormattedMessage id="stockMovement.a" defaultMessage="a" />
              </td>
              <td className={`${styles.headerData} ${styles.numCell}`}>
                <FormattedMessage id="stockMovement.b" defaultMessage="b" />
              </td>
              <td className={`${styles.headerData} ${styles.numCell}`}>
                <FormattedMessage id="stockMovement.c" defaultMessage="c" />
              </td>
              <td className={`${styles.headerData} ${styles.numCell}`}>
                <FormattedMessage id="stockMovement.d" defaultMessage="d" />
              </td>
              <td className={`${styles.headerData} ${styles.numCell}`}>
                <FormattedMessage id="stockMovement.e" defaultMessage="e" />
              </td>
              <td className={`${styles.headerData} ${styles.numCell}`}>
                <FormattedMessage id="stockMovement.f" defaultMessage="f" />
              </td>
            </tr>
          </thead>

          <tbody>
            <StockMovementsCategorySection
              rows={stock_movement_rows_delivery}
              totals={stock_movement_totals_row_delivery}
              getCommodity={getCommodity}
              title={
                <FormattedMessage
                  id="Common.onsiteSchoolFeedingModality"
                  defaultMessage="Deliveries for on-site meals"
                />
              }
            />
            <StockMovementsCategorySection
              rows={stock_movement_rows_purchasedetail}
              totals={stock_movement_totals_row_purchasedetail}
              getCommodity={getCommodity}
              title={
                <FormattedMessage
                  id="Common.purchasesSchoolFeedingModality"
                  defaultMessage="School purchases"
                />
              }
            />
            <StockMovementsCategorySection
              rows={stock_movement_rows_takehomeration}
              totals={stock_movement_totals_row_takehomeration}
              getCommodity={getCommodity}
              title={
                <FormattedMessage
                  id="Common.thrSchoolFeedingModality"
                  defaultMessage="Deliveries for Take-Home rations"
                />
              }
            />
          </tbody>
        </TableExtended>
      </div>
    </ModuleBody>
  );
};

export default StockMovements;
